// import {
//   Link,
// } from "react-router-dom";
// import Picker from 'react-mobile-picker';
// import {ReactComponent as Woman} from '../../icons/woman.svg';
// import {ReactComponent as Men} from '../../icons/men.svg';
function App() {

    // const data = {
    //   valueGroups: {
    //     title: 'Mr.',
    //     firstName: 'Micheal',
    //     secondName: 'Jordan'
    //   }, 
    //   optionGroups: {
    //     firstName: ['John', 'Micheal', 'Elizabeth'],
    //     secondName: ['Lennon', 'Jackson', 'Jordan', 'Legend', 'Taylor']
    //   }
    // };
    // const handleChange = (e,name, value) => {
    // this.setState(({valueGroups}) => ({
    //   valueGroups: {
    //     ...valueGroups,
    //     [name]: value
    //   }
    // }));
    // };
    return (
    <>
        <div className="text-center grid grid-cols-2">
            <div>
                น้ำหนัก
            </div>
            <div>
                ส่วนสูง
            </div>
            <div className="col-span-2">
                {/* <Picker
                optionGroups={data.optionGroups}
                valueGroups={data.valueGroups}
                onChange={handleChange} /> */}
            </div>
        </div>
            
    </>
    );
}

export default App;
