// import {
//   Switch,
//   Route,
//   useRouteMatch,
// } from "react-router-dom";
import Chart from "react-apexcharts";
// import Style from "../css/main.css";

import {ReactComponent as Wave2} from '../icons/wave.svg';


function App() {
     const ez = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
        legend: {
            show: false,
        },
      },
     series: [25, 15, 44, 55, 41, 17],
     
    };
    return (
    <>
        <div className="flex flex-col">
            <div className="bg-red-500 h-56 mb-5 items-center grid">
                <div className="grid grid-cols-3">
                    <div className="pt-5">Last</div>
                    <div className="">
                        <div>Completion</div>
                        <div className="text-2xl">5%</div>
                    </div>
                    <div className="pt-5">Next</div>

                </div>
            </div>
            <div className="-mt-14">
                <Wave2 />
            </div>
            <Chart
              options={ez.options}
              series={ez.series}
              type="pie"
              width="100%"
            />
        </div>
        <div className="absolute bottom-3 w-full">
            1234
        </div>
    </>
    );
}

export default App;
