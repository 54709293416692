import {
  BrowserRouter as Router,
  Switch,
  Route} from "react-router-dom";
import './App.css';
import Register from './pages/register';
import Main from './pages/main';
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/register">
              <Register/>
          </Route>
          <Route path="/main">
              <Main/>
          </Route>
          <Route path="/">
            <div className="p-10">adasda</div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
