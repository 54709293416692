import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Step1 from '../components/register/step1';
import Step2 from '../components/register/step2';
function App() {
    let match = useRouteMatch();
    return (
    <>
        <div className="bg-red-500 h-56 mb-5 items-center grid">
            <div className="text-center">Header</div>
        </div>
        <div className="">
            <Switch>
                <Route path={`${match.path}/step1`}>
                    <Step1/>
                </Route>
                <Route path={`${match.path}/step2`}>
                    <Step2/>
                </Route>
                <Route path={match.path}>
                <h3>Please select a topic.</h3>
                </Route>
            </Switch>
        </div>
    </>
    );
}

export default App;
