import {
  Link,
} from "react-router-dom";

// import {ReactComponent as Woman} from '../../icons/woman.svg';
import {ReactComponent as Men} from '../../icons/men.svg';
function App() {
    return (
    <>
        <div className="text-center grid grid-cols-2">
            <div className="col-span-2">
            Please hoose your gender
            </div>
            <div className="p-5">
                <Men className="w-full" />
            </div>
            <div className="p-5">
                <Men className="w-full fill-current text-red-100" />
                
                {/* <Woman className="w-full"/> */}
            </div>
        </div>
        <div className="absolute bottom-3 w-full">
            <Link to="step2">
                <button className="bg-blue-400 p-2 w-4/5 rounded-3xl">Next</button>
            </Link>
        </div>
    </>
    );
}

export default App;
